/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from "react";
// import MainScreen from "./../MainScreen/MainScreen.component";
import firepadRef, { db } from "./../../server/firebase";
import { useEffect } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
} from "../../store/actioncreator";
// import blockedVideo from "../../media/blockedVideo.svg";

import { connect } from "react-redux";
import CallInitialPage from "../CallInitialPage/CallInitialPage";
// import { useParams, useHistory } from "react-router-dom";
import { initRef } from "../../server/peerConnection";
import { getUserStream, sanitizeString } from "../../util/helper";
import useDatabase from "../../hooks/useDatabase";
import { useState } from "react";
import ErrorDialog from "../UI/ErrorDialog/ErrorDialog";
import NoMatch from "../NoMatch/NoMatch";
import MainScreenComponent from "../MainScreen/MainScreen.component";
// import micIcon from "../../media/microphone.png";
import "./CallPage.scss";
import { uid, userName } from "../../App";
import { APP_NAME } from "../../util/constants";
function CallPage(props) {
  console.log("callpage called");
  const [showCallPage, setShowCallPage] = React.useState(false);
  const [roomId, setRoomId] = React.useState(null);
  const [userDBId, setUserDBId] = useState();
  const [showError, setShowError] = useState(null);

  const [configuration, setConfiguration] = React.useState({
    mic: true,
    video: false,
    screen: false,
  });
  useEffect(() => {
    if (!uid) return;
    console.log("calling create room ", props);
    const roomId = props.roomId;
    if (roomId) {
      // createRoom(roomId);
      setRoomId(roomId);
      setShowCallPage(true);
      initRef(roomId);
    } else console.log("rommid not found");
    document.title = `${userName}'s meeting`;
  }, [props.roomId]);
  useEffect(() => {
    if (!uid && uid === "") return;
    const executeAction = async () => {
      const urlparams = new URLSearchParams(window.location.search);
      const audio = urlparams.get("audio");
      const video = urlparams.get("video");
      const screen = urlparams.get("screen");
      var config = {
        mic: audio !== undefined ? audio === "true" : true,
        video: video !== undefined ? video === "true" : false,
        screen: screen !== undefined ? screen === "true" : false,
      };
      //props.router(window.location.pathname);
      window.history.pushState("", "", window.location.pathname);

      console.log("Config", config);
      setConfiguration({ ...configuration, ...config });
      try {
        const stream = await getUserStream();
        // stream.getVideoTracks()[0].enabled = false;
        stream.getVideoTracks()[0].enabled = config.video;
        props.setMainStream(stream);
      } catch (error) {
        try {
          const stream = await getUserStream(true, false);
          console.log(error);
          props.setMainStream(stream);
        } catch (error) {
          setShowError("page");
          console.log(" both error ocurred", error);
        }
      }

      connectedRef.on("value", (snap) => {
        if (snap.val()) {
          const defaultPreference = {
            // audio: true,
            // video: false,
            ...configuration,
            ...config,
          };
          console.log("default pref", defaultPreference);
          //creating user in the database

          const tempId = uid.replace(".", "").replace("@", "");
          participantRef
            .child(tempId)
            .update({
              userName,
              uid: uid,
              preferences: defaultPreference,
            })
            .then(() => {
              const userStatusRef = participantRef.child(tempId);
              console.log("pushed user", userStatusRef, userStatusRef.key);
              props.setUser({
                [tempId]: {
                  name: userName,
                  uid: uid,
                  ...defaultPreference,
                },
              });
              setUserDBId(tempId);
              try {
                userStatusRef.onDisconnect().remove();
              } catch (error) {
                console.log("disconnet listener", error);
              }
            });
        }
      });
    };
    executeAction();
  }, []);

  const connectedRef = db.database().ref(".info/connected");
  var participantRef = firepadRef.child(props.roomId).child("participants");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;
  const { docs, files, settings, emojiList, participantsStatus } = useDatabase(
    props.roomId
  );

  console.log("docs ", docs, files, "currentUser", userDBId);
  useEffect(() => {
    if (!uid) return;
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const { userName: name, uid, preferences = {} } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            uid,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
        if (snap.key === sanitizeString(uid)) {
          window.location.href = "/";
        }
        console.log(
          "removed child",
          snap.val(),
          snap.key,
          "dbId",
          uid.replace(".", "").replace("@", "")
        );
      });
    }
  }, [isStreamSet, isUserSet]);

  return (
    <>
      <div
        className="App"
        style={{
          "user-select": "none",
        }}
      >
        {showCallPage ? (
          showError === "page" ? (
            <NoMatch
              actionBtName="Retry"
              actionMethod={() => window.location.reload()}
              msg={
                <>
                  MetaCalls requires access to your camera and microphone. Click
                  the camera {"blocked icon "}
                  <img
                    alt=""
                    aria-hidden="true"
                    style={{ verticalAlign: "middle" }}
                    src="/blockedVideo.svg"
                  />
                  {" in your browser's address bar."}
                </>
              }
              customImage="/microphone.png"
            />
          ) : (
            <MainScreenComponent
              roomId={roomId}
              attachmentList={files}
              configuration={configuration}
              messageList={docs}
              emojiList={emojiList}
              showError={setShowError}
              participantsStatusList={participantsStatus}
              settings={settings}
              currentUserDBId={userDBId}
            />
          )
        ) : (
          <CallInitialPage />
        )}
      </div>
      {showError && showError !== "page" && (
        <ErrorDialog removeDialog={() => setShowError(null)} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallPage);
