import React, { useEffect, useState } from "react";
import { getSession, handleUnAuthSession, login, logout } from "../server/auth";
import { useNavigate, useParams } from "react-router-dom";
import CallInitiator from "../components/CallInitiator/CallInitiator";
import CallInitialPage from "../components/CallInitialPage/CallInitialPage";
import { CircularProgress } from "@mui/material";

export default function Meet() {
  console.log("meet page ");
  const history = useNavigate();
  const { roomId } = useParams();

  return <InitiateMeeting roomId={roomId} history={history} />;
}
function InitiateMeeting({ roomId, history }) {
  const [user, setUser] = useState(null);
  if (!user) getSession(setUser, true, user);
  //check if url have ?video=&&audio=
  let tempUrl = window.location.href.split("?");
  console.log("checking meeting", roomId, tempUrl);
  if (roomId) {
    if (tempUrl.length > 1) {
      const url = new URLSearchParams(tempUrl[1]);
      if (!url.has("video") && url.has("audio"))
        return (window.location.href = "/meet/id=" + roomId);
    } else return <CallInitialPage user={user} logout={logout} />;
  }

  return user ? (
    !roomId ? (
      <CallInitialPage user={user} logout={logout} />
    ) : (
      <CallInitiator roomId={roomId} session={user} router={history} />
    )
  ) : (
    <div className="centered-div">
      <CircularProgress style={{ marginTop: "19%" }} />
      <p>Creating meeting</p>
    </div>
  );
}
