import { useState, useEffect } from "react";
import { uid, userName } from "../App";
import { db } from "../server/firebase";
import { ALLOW_AUDIO, ALLOW_VIDEO, APP_NAME } from "../util/constants";
import {
  deleteArrayElement,
  findObj,
  isAtBottom,
  scrollChatToBottom,
} from "../util/helper";
const useDatabase = (docId) => {
  const [docs, setDocs] = useState([]);
  const [files, setFiles] = useState([]);
  const [settings, setSettings] = useState([]);
  const [emojiList, setEmojiList] = useState([]);
  const [participantsStatus, setParticipantsStatus] = useState([]);

  useEffect(() => {
    let userId = userName;
    if (!userId) return null;
    var dbDoc;

    dbDoc = db.database().ref().child(APP_NAME).child(docId).child("data");
    try {
      var unsub1;
      var unsub2;
      unsub1 = dbDoc
        .child("chat")
        .on("child_added", (snapshot, prevChildKey) => {
          const newPost = snapshot.val();
          console.log("existing docs are", docs);
          //check if key exist;
          if (docs.findIndex((item) => item.key === snapshot.key) === -1) {
            docs.push({ ...newPost, key: snapshot.key });
            setDocs([...docs]);
            if (isAtBottom("chat-section")) {
              scrollChatToBottom(true);
              console.log("chat at bottom");
            } else console.log("chat not at bottom");
          }
        });
      unsub2 = dbDoc
        .child("files")
        .on("child_added", (snapshot, prevChildKey) => {
          const newPost = snapshot.val();
          console.log("existing docs are", docs);
          if (files.findIndex((item) => item.key === snapshot.key) === -1) {
            files.push({ ...newPost, key: snapshot.key });
            setFiles([...files]);
            if (isAtBottom("chat-section")) {
              scrollChatToBottom(true);
              console.log("chat at bottom");
            } else console.log("chat not at bottom");
          }
        });

      var unsub3 = dbDoc
        .child("files")
        .on("child_removed", (snapshot, prevChildKey) => {
          const newPost = snapshot.val();
          console.log("deleted docs is", newPost);
          let list = deleteArrayElement(
            files,
            findObj(newPost.fileUrl, files, "fileUrl")
          );

          setFiles([...list]);
        });
      var unsub4 = dbDoc.child("settings").on("value", function (dataSnapshot) {
        //set admin name
        setSettings(dataSnapshot.val());
        console.log("settings page", dataSnapshot.val());

        document.title = `${
          dataSnapshot.val().userName
            ? dataSnapshot.val().userName + " 's Meeting"
            : "MetaCalls | Joined a meeting"
        }`;
        //check settings
        if (
          dataSnapshot.val()[ALLOW_AUDIO] &&
          uid !== dataSnapshot.val().admin &&
          document.getElementById("audio-item").classList.contains("red-bg")
        ) {
          document.getElementById("audio-item").click();
        }
        if (
          dataSnapshot.val()[ALLOW_VIDEO] &&
          uid !== dataSnapshot.val().admin &&
          document.getElementById("video-item").classList.contains("red-bg")
        ) {
          document.getElementById("video-item").click();
        }
      });

      const unsub5 = dbDoc
        .child("emoji")
        .on("child_added", (snapshot, prevChildKey) => {
          const newPost = snapshot.val();
          snapshot.ref.remove();
          console.log("existing docs are", docs);
          if (emojiList.findIndex((item) => item.key === snapshot.key) === -1) {
            emojiList.push({ ...newPost, key: snapshot.key });
            setEmojiList([...emojiList]);
          }
        });
      const unsub6 = dbDoc
        .child("participants-status")
        .on("value", function (dataSnapshot) {
          let temp = [];

          if (dataSnapshot.val()) {
            Object.keys(dataSnapshot.val()).forEach((key) => {
              temp.push(dataSnapshot.val()[key]);
            });
          }
          setParticipantsStatus(temp);
        });

      dbDoc.once("value", (snapshot) => {
        let docList = [];
        let val = snapshot.val();
        try {
          if (val.chat)
            val.chat.forEach((doc) => {
              console.log(doc.val());
              docList.push(doc.val());
            });
          console.log("once data", snapshot.val());
          setDocs(docList);
        } catch (error) {}
        try {
          let fileList = [];
          if (val.files)
            val.files.forEach((doc) => {
              console.log(doc.val());
              fileList.push(doc.val());
            });
          setFiles(fileList);
        } catch (error) {}
      });

      return () => {
        try {
          unsub1();
          unsub2();
          unsub3();
          unsub4();
          unsub5();
          unsub6();
        } catch (error) {}
      };
    } catch (error) {
      console.log(error);
    }
  }, [userName, docId]);
  return { docs, files, settings, emojiList, participantsStatus };
};

export default useDatabase;
