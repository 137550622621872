import React from "react";
import style from "./NoMatch.scss";
import Header from "../UI/Header/Header";
import { CircularProgress } from "@mui/material";
export default function NoMatch({
  msg,
  actionBtName,
  actionMethod,
  showProgress,
  customImage,
}) {
  return (
    <div className={"no_match"}>
      <Header />
      {customImage && (
        <div style={{ textAlign: "center" }}>
          <img src={customImage} alt="Microphone" width={250} height={250} />
        </div>
      )}
      <div className={"no_match__content"}>
        <h2 className={"actionBtn"}>{msg ?? "Invalid video call name"}</h2>
        {actionMethod ? (
          <button className={`${"btn"} green`} onClick={actionMethod}>
            {actionBtName}
          </button>
        ) : (
          !showProgress && (
            <a className={`${"btn"} green`} href="/">
              Return to home screen
            </a>
          )
        )}
        {showProgress && <CircularProgress color="primary" />}
      </div>
    </div>
  );
}
