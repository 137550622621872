import React, { Component } from "react";
import { getSession } from "../server/auth";
import UI from "./components/UI/UI";

export class AuthHome extends Component {
  state = {
    user: null,
  };
  setUser = (val) => {
    this.setState({ user: val });
  };
  render() {
    document.getElementsByTagName("body")[0].style = ` width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    background-color: #ecf0f3;
    color: #a0a5a8;`;

    if (!this.state.user) getSession(this.setUser, false);
    else {
      if (!window.location.href.includes("auth")) window.location.href = "/";
    }
    console.log("auth user", this.state.user);
    return (
      <div>
        <UI />
      </div>
    );
  }
}

export default AuthHome;
