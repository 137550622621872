import "./Carousel.css";
import React, { Component } from "react";

export class Carousel extends Component {
  state = {
    current: 0,
  };
  componentDidMount() {
    const slidesContainer = document.getElementById("slides-container");
    const slide = document.querySelector(".slide");
    const prevButton = document.getElementById("slide-arrow-prev");
    const nextButton = document.getElementById("slide-arrow-next");

    nextButton.addEventListener("click", (event) => {
      const slideWidth = slide.clientWidth;
      slidesContainer.scrollLeft += slideWidth;
      if (this.state.current !== this.props.images.length - 1)
        this.setState({ current: this.state.current + 1 });
    });

    prevButton.addEventListener("click", () => {
      const slideWidth = slide.clientWidth;
      slidesContainer.scrollLeft -= slideWidth;
      if (this.state.current !== 0)
        this.setState({ current: this.state.current - 1 });
    });
  }
  handlePositionClick(index) {
    console.log(index);
    document.getElementById("carousel-" + index).scrollIntoView();
    this.setState({ current: index });
  }
  render() {
    const { images } = this.props;
    return (
      <React.Fragment>
        {images && images.length && (
          <section className="slider-wrapper">
            <button className="slide-arrow" id="slide-arrow-prev">
              &#8249;
            </button>

            <button className="slide-arrow" id="slide-arrow-next">
              &#8250;
            </button>

            <ul className="slides-container" id="slides-container">
              {images.map((img, index) => (
                <img
                  id={"carousel-" + index}
                  key={img}
                  alt="app-snapshot"
                  src={img}
                  className="slide"
                />
              ))}
            </ul>
          </section>
        )}
        <div className="dot-container">
          {images.map((item, index) => {
            return (
              <div
                key={index + index}
                className="dot"
                style={{
                  backgroundColor:
                    this.state.current === index ? "black" : "gray",
                }}
                onClick={() => this.handlePositionClick(index)}
              ></div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default Carousel;
