import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faExclamationCircle,
  faCog,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import style from "./Header.css";
import { Tooltip } from "@mui/material";
import { logout } from "../../../server/auth";
function Header() {
  // console.log("header session", session);
  const user = {
    image: localStorage.getItem("userImage"),
    name: localStorage.getItem("userName"),
  };
  const handleLogout = () => {
    if (user) logout();

    window.location.href = "/";
  };
  const openHome = () => {
    window.location.href = "/";
  };
  return (
    <div className={"header"}>
      <div className={"logo"} onClick={openHome}>
        <img src="/app.png" alt="MetaCalls" />
        <span className={"helpText"}>MetaCalls</span>
      </div>
      <div className={"actionBtn"} onClick={handleLogout}>
        {user.name ? (
          <>
            <Tooltip title={`${user.name ? user.name : ""} (Logout)`}>
              {user.image && user.image !== "null" ? (
                <img
                  className={"profile_image"}
                  src={
                    user.image && user.image !== "null"
                      ? user.image
                      : "/user.svg"
                  }
                  alt={user.name ? user.name : ""}
                  width={30}
                  height={30}
                />
              ) : (
                <FontAwesomeIcon
                  className={`${"icon"} ${"profile"}`}
                  icon={faUserCircle}
                  style={{
                    fontSize: "32px",
                    color: "#04796a",
                    cursor: "pointer",
                  }}
                />
              )}
            </Tooltip>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
