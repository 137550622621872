import React from "react";
import Card from "../../Card/Card.component";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./Participant.css";
import { useMediaQuery } from "@mui/material";
import { screenSizes } from "../../../util/helper";

export const Participant = (props) => {
  const xs = useMediaQuery(screenSizes.xs);
  const {
    curentIndex,
    currentParticipant,
    hideVideo,
    videoRef,
    showAvatar,
    currentUser,
    isOneUser,
  } = props;
  if (!currentParticipant) return <></>;

  return (
    <div
      style={isOneUser && !xs ? { width: "80%", justifySelf: "center" } : null}
      className={`${"participant"} ${hideVideo ? "hide" : ""}`}
    >
      <Card>
        <video
          ref={videoRef}
          className={"video"}
          id={`participantVideo${curentIndex}`}
          autoPlay
          playsInline
        ></video>
        {!currentParticipant.audio && (
          <FontAwesomeIcon
            className={"muted"}
            icon={faMicrophoneSlash}
            title="Muted"
          />
        )}
        {showAvatar && (
          <div
            style={{ background: currentParticipant.avatarColor }}
            className={"avatar"}
          >
            {currentParticipant.name[0]}
          </div>
        )}
        <div className={"name"}>
          {currentParticipant.name}
          {currentUser ? "(You)" : ""}
        </div>
      </Card>
    </div>
  );
};
