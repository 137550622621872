import {
  faDesktop,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { createRoom, joinRoom } from "../../server/firebase";
import {
  generateUniqueId,
  getRoomId,
  getUserStream,
  screenSizes,
} from "../../util/helper";
import Header from "../UI/Header/Header";
import style from "./CallInitialPage.scss";
// import { withWidth } from "@mui/material/useMediaQuery";
function CallInitialPage(props) {
  const [config, setConfig] = React.useState({
    video: true,
    mic: true,
    screen: false,
  });
  let { roomId } = useParams();
  const xs = useMediaQuery(screenSizes.xs);
  const startCall = () => {
    //check if roomid is passed

    const roomUniqueId = getRoomId(roomId) ?? generateUniqueId();
    if (!roomId) createRoom(roomUniqueId);
    else joinRoom(roomId);
    window.location.href =
      "/meet/" +
      roomUniqueId +
      "?video=" +
      config.video +
      "&audio=" +
      config.mic;
  };
  const shareScreen = () => {
    const roomUniqueId = getRoomId(roomId) ?? generateUniqueId();
    if (!roomId) createRoom(roomUniqueId);
    else joinRoom(roomId);
    window.location.href = "/meet/" + roomUniqueId + "?screen=" + true;
  };
  React.useEffect(() => {
    init();
  }, [config]);

  const init = async () => {
    let ref = document.getElementById("user-video");
    try {
      let stream = await getUserStream(false, config.video);
      ref.srcObject = stream;
    } catch (error) {
      ref.srcObject = null;
    }
    //check if passed in param
  };
  const videoPreview = (
    <div className={"right_side"}>
      <div
        className={"content"}
        style={{
          background: "black",
          border: "1px solid black",
          padding: 0,
          paddingBottom: 37,
        }}
      >
        <video
          className={"iframe_container"}
          id="user-video"
          autoPlay
          playsInline
          style={
            xs
              ? {
                  width: "100%",
                  height: "inherit",
                  borderRadius: 0,
                  objectFit: "scale-down",
                }
              : { objectFit: "scale-down" }
          }
          poster="no-video.png"
        ></video>
        <div
          className="footer-item"
          style={{
            position: "relative",
            height: 0,
          }}
        >
          <div className="center-item">
            <div
              style={{ border: 0 }}
              data-tip={config.mic ? "Mute Audio" : "Unmute Audio"}
              className={`icon-block ${config.mic ? "red-bg" : null}`}
              onClick={() => setConfig({ ...config, mic: !config.mic })}
            >
              <FontAwesomeIcon
                className="icon"
                icon={config.mic ? faMicrophone : faMicrophoneSlash}
              />
            </div>

            <div
              style={{ border: 0 }}
              className={`icon-block ${config.video ? "red-bg" : null}`}
              onClick={() => setConfig({ ...config, video: !config.video })}
            >
              <FontAwesomeIcon
                className="icon"
                icon={config.video ? faVideo : faVideoSlash}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className={"home_page"}>
      <Header user={props.user} logout={props.logout} />
      <div
        className={"body"}
        style={xs ? { display: "block", alignSelf: "center" } : null}
      >
        {xs ? videoPreview : null}
        <div className={"left_side"}>
          <div className={"content"}>
            {!xs ? (
              <>
                <h2>Premium video meeting. Now free for everyone.</h2>
                <p>
                  We re-engineered the service we built for secure business
                  meetings, Meta Calls to make it free and available for all.
                </p>
              </>
            ) : null}
            <div
              className={"action_btn"}
              style={{ justifyContent: "flex-start" }}
            >
              <button onClick={startCall} className={`${"btn"} green`}>
                <FontAwesomeIcon className={"icon_block"} icon={faVideo} />
                Start Meeting
              </button>
              <button className={"btn"} onClick={shareScreen}>
                <FontAwesomeIcon className={"icon_block"} icon={faDesktop} />
                Share Screen
              </button>
              <button
                onClick={() => (window.location.href = "/")}
                className={"btn"}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className={"help_text"}>
            <a href="">Learn more</a> about MetaCalls
          </div>
        </div>
        {!xs ? videoPreview : null}
      </div>
    </div>
  );
}
export default // withWidth()
CallInitialPage;
