import {
  faCopy,
  faShieldAlt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { userName } from "../../../App";
import style from "./MeetingInfo.scss";
export default function MeetingInfo({ setMeetInfoPopup, url }) {
  const copyUrl = () => {
    navigator.clipboard.writeText(url);
  };
  return (
    <div className={"meeting_info_block"}>
      <div className={"meeting_header"}>
        <h3>Your meeting&apos;s ready</h3>
        <FontAwesomeIcon
          className={"icon"}
          onClick={(e) => setMeetInfoPopup(false)}
          icon={faTimes}
        />
      </div>

      <p>Share this meeting link with others you want in the meeting</p>
      <div className={"meet_link"}>
        <span>{url}</span>
        <FontAwesomeIcon
          style={{ paddingLeft: 4 }}
          onClick={copyUrl}
          className={"icon"}
          icon={faCopy}
        />
      </div>
      <div className={"permission_text"}>
        <FontAwesomeIcon className={"icon"} icon={faShieldAlt} />
        <p className={"small_text"}>
          People who use this meeting link must get your permission before they
          can join
        </p>
      </div>
      <p className={"small_text"}>Joined as {userName}</p>
    </div>
  );
}
