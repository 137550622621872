import { useState } from "react";
import HomePage from "../components/HomePage/HomePage";
import { getSession } from "../server/auth";

export default function Home() {
  console.log("inside home");
  const [user, setUser] = useState();
  if (!user) getSession(setUser, false);

  return (
    <>
      <HomePage />
    </>
  );
}
