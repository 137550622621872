import { faKeyboard, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Header from "../UI/Header/Header";
import style from "./HomePage.scss";

import JoinCall from "../JoinCall/JoinCall";
import { useMediaQuery } from "@mui/material";
import { screenSizes } from "../../util/helper";
import Carousel from "../UI/Carousel/Carousel";
function HomePage(props) {
  const [roomId, setRoomId] = React.useState(null);
  const startCall = () => {
    window.location.href = "/meet";
  };
  const xs = useMediaQuery(screenSizes.xs);
  const joinCall = () => {
    try {
      console.log("joining call");
      let meet = document.getElementById("join-call-input").value;
      let roomId;
      console.log("meet link in homepage", meet);
      if (meet) {
        if (meet.startsWith(window.location.protocol)) {
          if (meet.includes(window.location.host)) {
            // const urlparams = new URLSearchParams(new URL(meet).search);
            // roomId = urlparams.get("id");

            let temp = meet.split("/");
            if (temp[temp.length - 1].length > 3)
              roomId = temp[temp.length - 1];
            else if (temp[temp.length - 2].length > 3)
              roomId = temp[temp.length - 2];

            console.log("room id", roomId);
          }
        } else roomId = meet;
        if (roomId) {
          setRoomId(roomId);
        } else console.log("Room id not found");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const coursel = (
    <Carousel
      images={[
        "/images/app-snap1.png",
        "/images/app-snap4.png",
        "/images/app-snap2.png",
        "/images/app-snap3.png",
      ]}
    />
  );
  return (
    <>
      <div className={"homePage"}>
        <Header />
        <div className={"body"} style={xs ? { display: "block" } : null}>
          {xs && (
            <div className={"rightSide"}>
              <div className={""}>
                {/* <img
                  src="https://secure.skypeassets.com/content/dam/scom/home-new/hero-banner/3x3-tablet-min.png"
                  alt="video-call"
                /> */}
                {coursel}
              </div>
            </div>
          )}
          <div className={"leftSide"}>
            <div className={"content"}>
              <h2>Premium video meeting. Now free for everyone.</h2>
              <p>
                We re-engineered the service we built for secure business
                meetings, Meta Calls to make it free and available for all.
              </p>
              <div
                className={"actionBtn"}
                style={xs ? { display: "block" } : null}
              >
                <button onClick={startCall} className={`${"btn"} green`}>
                  <FontAwesomeIcon className={"iconBlock"} icon={faVideo} />
                  New Meeting
                </button>
                <div
                  className={"inputBlock"}
                  style={xs ? { marginLeft: 0, marginTop: 10 } : null}
                >
                  <div className={"inputSection"}>
                    <FontAwesomeIcon
                      className={"iconBlock"}
                      icon={faKeyboard}
                    />
                    <input
                      id="join-call-input"
                      placeholder="Enter a code or link"
                    />
                  </div>
                  <JoinCall
                    style={style}
                    roomId={roomId}
                    joinCallFn={joinCall}
                  />
                </div>
              </div>
            </div>
            <div className={"helpText"}>
              <a href="#">Learn more</a> about MetaCalls
            </div>
          </div>
          {!xs && (
            <div className={"rightSide"}>
              <div className={""}>
                {/* <img
                  src="https://secure.skypeassets.com/content/dam/scom/home-new/hero-banner/3x3-tablet-min.png"
                  alt="video-call"
                /> */}
                {coursel}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default // withWidth()
HomePage;
