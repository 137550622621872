import "./App.css";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createStore } from "redux";
import { userReducer } from "./store/reducer";
import Home from "./pages/home";
import Meet from "./pages/meet";
import Login from "./pages/login";
import AuthHome from "./auth/authHome";
import { createTheme } from "@mui/material";
export const store = createStore(userReducer);
var uid = "";
var userName = "";
var userImage = "";
try {
  uid = localStorage.getItem("uid");
  userName = localStorage.getItem("userName");
  userImage = localStorage.getItem("userImage");
} catch (error) {
  console.log(error);
}
export { uid };
export { userName };
export { userImage };
// export function uid() {
//   return localStorage.getItem("uid");
// }
document.title = `Meta Calls`;

function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#00685c",
      },
      primary: {
        main: "#00996b",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/meet" element={<Meet />} />
            <Route path="/meet/:roomId" element={<Meet />} />

            <Route path="/login" element={<Login />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/auth" element={<AuthHome />} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
